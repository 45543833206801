import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AgreementComponent} from './agreement/agreement.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {AboutUsComponent} from './about-us/about-us.component';


const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'agreement', component: AgreementComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  // { path: '**', component: FirstComponent },
  // { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
