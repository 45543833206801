<div class="agreement-container">
  <div class="agreement-header"> Denemapp Kullanıcı Sözleşmesi</div>

  <div class="agreement-text">
    <div>Denemapp Kullanım Sözleşmesi Taraflar İşbu Denemapp Kullanım Sözleşmesi (“Sözleşme”),
      www.denemapp.com adlı web sitesinin ve Denemapp mobil uygulamalarının yaratıcısı ve her
      türlü fikri ve sınai mülkiyet hakkının sahibi olan KALKÜLÜS EĞİTİM VE BİLİŞİM TEKNOLOJIİLERİ TİCARET VE
      SANAYİ LİMİTED ŞİRKETİ ile, www.denemapp.com web sitesinin ve Denemapp mobil uygulamalarının içeriğinden
      ve hizmetlerinden yararlanmak isteyen gerçek veya tüzel kişi kullanıcı arasında geçerli olup, www.denemapp
      .com web sitesini ve/veya Denemapp mobil uygulamalarını kullanmak,verilen hizmetlerden ve sunulan içeriklerden
      yararlanmak için gereken hüküm ve şartları düzenlemektedir. Tanımlar Bu sözleşmede:
    </div>
    <div>2. Denemapp: KALKÜLÜS EĞİTİM VE BİLİŞİM TEKNOLOJİLERİ TİCARET VE SANAYİ LİMİTED ŞİRKETİ’ni
    </div>
    <div>3.  Denemapp Hizmeti: denemapp.com adlı web sitesi, ve Denemapp mobil uygulamaları dahil olmak üzere KALKÜLÜS
      EĞİTİM VE BİLİŞİM TEKNOLOJİLERİ TİCARET VE SANAYİ LİMİTED ŞİRKETİ tarafından sunulan herhangi bir uygulama,
       içerik hizmetini,
    </div>
    <div>4. Kullanıcı: Denemapp Hizmetinden yararlanmak isteyen gerçek veya tüzel kişileri,
    </div>
    <div>5. Hesap: Denemapp Hizmetinde paylaşılan her türlü bilgi ile oluşturulan Kullanıcı
      hesabını,
    </div>
    <div>6. İçerik: Kullanıcıların Denemapp Hizmetine gönderdiği veya Denemapp Hizmetinde
      paylaştığı ve/veya gösterdiği tüm veriler, metinler, dosyalar, bilgiler, kullanıcı adları,
      görseller, grafikler, fotoğraflar, hesap bilgileri, ses ve video klipleri, sesler, müzik
      eserleri, özgün çalışmalar, uygulamalar, bağlantılar ve diğer içerik ve malzemeleri,
    </div>
    <div>7. Denemapp Tarafları: Denemapp çalışanları, müdürleri, görevlileri veya temsilcilerini
      ifade eder.
    </div>
    <div>8. Denemapp Hizmetini kullanmak için işbu Sözleşme hüküm ve şartlarının Kullanıcı
      tarafından kabul ve taahhüt edilmesi gerekmektedir. Denemapp Hizmetine
      eriştiğinizde ve/veya kullandığınızda, nasıl eriştiğinize bakılmaksızın, bu Sözleşmeye
      tabi olduğunuzu kabul edersiniz. Bu Sözleşmeye tabi olmayı kabul etmiyorsanız,
      Denemapp Hizmetine erişmemeniz ve Denemapp Hizmetini kullanmamanız
      gerekmektedir.
    </div>
    <div>9. Kullanıcı, Sözleşme’yi kabul ettiğinde sözleşme yürürlüğe girer ve Denemapp
      Hizmetine erişime ve kullanmaya yetkili olduğunu, hizmetlere erişimde, hizmetlerin
      seçiminde ve kullanımında her türlü sorumluluğu üstlendiğini kabul, beyan ve
      taahhüt eder.
    </div>
    <div>10. İşbu Sözleşmeyi kabul etmekle birlikte gerçek kişi Kullanıcı 18 yaşından büyük
      olduğunu ve sözleşme yapmak için gereken fiil ehliyetine sahip olduğunu, tüzel kişi
      Kullanıcı ise, bu Sözleşme’nin yetkili kişiler tarafından kabul edildiğini ve tüzel kişi
      için bağlayıcı olduğunu kabul ve beyan ederler.
    </div>
    <div>11. Kullanıcı 18 yaşından küçük olması halinde Denemapp Hizmetinden velisinin onayı
      ve vasıtasıyla faydalanabileceğini kabul eder.
    </div>
    <div>12. Denemapp eğitim ve öğrenim amaçlı dijital bir pazaryeridir. Denemapp Hizmeti,
      Denemapp pazaryerine erişim sağlanmasından ve kullanılmasından ibarettir.
    </div>
    <div>13. Kullanıcılar aldıkları hizmet karşılığını satın aldıkları paketten düşülmesi kaydıyla
      Denemapp’a deneme başına ücret veya Denemapp’a haftalık veya aylık abonelik
      ücreti öder.
    </div>
    <div>14. Denemapp Hizmeti, deneme düzenlemer ve planlar, kullanıcılara mobil uygulama
      aracılığı ile sunar.
    </div>
    <div>15. Denemapp, Kullanıcıya, işbu Sözleşmeyi kabul etmesi ile beraber Denemapp
      Hizmetine erişim sağlamaktadır ve Denemapp Hizmetine erişim bu Sözleşmedeki
      hüküm ve şartlara uyulmasına bağlıdır.
    </div>
    <div>16. Denemapp, işbu Sözleşmeyi zaman zaman tamamen kendi takdirine bağlı olarak
      değiştirme (“Güncellenmiş Koşullar“) hakkına sahiptir. Söz konusu değişikliklerin,
      yasal veya idari nedenlerle derhal yürürlüğe sokulması gerektiği haller dışında,
      Denemapp, mümkün olduğunca, Güncellenmiş Koşullar yürürlüğe girmeden makul
      bir süre önce Kullanıcılara bildirimde bulunur.
    </div>
    <div>17. Güncellenmiş Koşullar, Denemapp Hizmetinde yayınlayarak Kullanacılara
      bildirilebileceğini ve Güncellenmiş Koşulların yürürlüğe girdiği tarihten sonra
      Denemapp Hizmetinin kullanılmasının (veya makul olarak belirtilen başka bir
      davranışta bulunulmasının) Güncellenmiş Koşulların kabul edildiği anlamına
      geleceğini Kullanıcı kabul eder. Bu yüzden, Denemapp Hizmetini kullanmadan önce
      işbu Sözleşmeyi ve tüm Güncellenmiş Koşulları gözden geçirmek Kullanıcının
      sorunluluğunuzdadır. Güncellenmiş Koşullar, yayınlandığı tarihte veya Güncellenmiş
      Koşullar’da belirtilen daha sonraki bir tarihte yürürlüğe girer ve o tarih itibariyle
      Denemapp Hizmeti Güncellenmiş Koşulları da Kullanıcıların kullanımını için geçerli
      olur. Güncellenmiş Koşullarda ortaya çıkan anlaşmazlıklar, işbu Sözleşmeye tabi
      olacaktır.
      Hesap Bilgilerinin Doğruluğu ve Güncel Tutulması İle İlgili Kullanıcının
      Sorumluluğu
    </div>
    <div>18. Denemapp Hizmetinde Kullanıcı tarafından paylaşılan her türlü bilgi ile oluşturulan
      Hesap aracılığıyla gerçekleşen her tür hareketin sorumluluğu Kullanıcıya aittir.
    </div>
    <div>19. Denemapp, Kullanıcının kendisinden başka herhangi biri adına Hesap oluşturmasını
      yasaklar ve Kullanıcı da oluşturmayacağını kabul eder. Ayrıca, Denemapp Hizmetine
      kayıt olurken, Hesap oluştururken ve takip eden diğer zamanlarda Kullanıcı, verdiği
      veya vereceği tüm bilgilerin gerçek, doğru, güncel ve eksiksiz olduğunu ve bilgilerin
      doğru ve gerçek kalmaları için değişiklikleri gecikmeksizin güncelleyeceğini taahhüt
      eder.
    </div>
    <div>20. Kullanıcı, Hesabında gerçekleşen veya Hesabı üzerinden gerçekleştirilen tüm
      işlemlerden sorumludur ve Hesabını, Hesaptaki iletişim bilgileri de dâhil tüm
      bilgilerini her zaman güvenli ve gizli tutacağını kabul eder.
    </div>
    <div>21. Kullanıcı doğru, tam ve güncel Hesap bilgileri sağlamaması halinde, Kullanıcının
      Denemapp Hizmetine erişimi ve Denemapp Hizmetini kullanımını engellenebilir
      veya Denemapp akdedilmiş olan her türlü sözleşmeyi (işbu Sözleşme dahil)
      feshedebilir.
    </div>
    <div>22. Kullanıcı, telefon numarasının değişmesi veya telefon hattının kapatılması
      durumunda, gönderilen mesajların doğru kişiye ulaşabilmesi için Hesap bilgilerini
      gecikmeksizin güncelleyeceğini taahhüt eder. Güncellenmeyen telefon numarasına
      gönderilen mesajların yanlış kişiye gitmesi halinde tüm sorumluluk Kullanıcıya aittir.
    </div>
    <div>23. Kullanıcı, mail adresinin değişmesi veya kapatılması durumunda, gönderilen
      mesajların doğru kişiye ulaşabilmesi için Hesap bilgilerini gecikmeksizin
      güncelleyeceğini taahhüt eder. Güncellenmeyen mail adresine gönderilen mesajların
      yanlış kişiye gitmesi halinde tüm sorumluluk Kullanıcıya aittir.
      Cayma Hakkı:
    </div>
    <div>24. Denemapp Hizmeti kapsamında internet sitesi üzerinden satın alınan her türlü
      aylık/haftalık veya özel olarak koşulları belirlenmiş farklı zaman dilimlerini kapsayan
      üyelik paketlerinin iptali mümkündür. Üyelik paketi hiç kullanılmadığı takdirde 14
      gün içerisinde iptali istenerek paket ücretinin tamamı Kullanıcıya iade edilir. Paket
      dahilinde eğer herhangi bir deneme çözülmüş ise üyelik kullanımı mevcut olup üyelik
      ücretinin iadesi yapılmaz. 14 gün geçtikten sonra yapılan üyelik iade talepleri
      geçersizdir.
    </div>
    <div>25. Denemapp Hizmeti kapsamında internet sitesi üzerinden satın alınan bir abonelik
      paketinin sonlandırılması mümkündür. Abonelik paketi, talep alındığı gün
      sonlandırılır, geçerli olduğu tarihe kadar kalan kullanım hakları devam eder ve iptal
      isteğini takip eden ayda otomatik ödeme gerçekleştirilmez
    </div>
    <div>26. Apple App Store veya Google Play gibi platform kullanılarak yapılan her türlü paket
      satın alma iptallerinde veya abonelik sonlandırmalarında, iptal başvurusu veya
      abonelik sonlandırma Apple’a veya Google’a yapılır. Bu başvurularda Apple ve
      Google’ın iptal koşulları ve kuralları geçerli olacaktır. Bu iptal veya abonelik
      sonlandırma başvurularında Denemapp’in hiçbir sorumluluğu yoktur.
    </div>
    <div>27. İadeler satın alma yapılırken kullanılan ödeme yöntemine göre yapılır. İade işlemi
      Denemapp tarafından yapıldıktan sonra ilgili ödeme sistemi sağlayıcısı tarafından
      işleme alınması zaman alabilir. Denemapp bu gecikmelerde sorumluluk kabul etmez.
      Hesap Kullanımı İle İlgili Kurallar
    </div>
    <div>28. Denemapp tarafından yazılı olarak izin verilmediği sürece, Kullanıcı sadece bir
      Hesaba sahip olabilir.
    </div>
    <div>29. Denemapp tarafından yazılı olarak izin verilmediği sürece, hizmetler münferiden
      Kullanıcının kendi kullanımı için geçerlidir.
    </div>
    <div>30. Kullanıcı, diğer Kullanıcıların giriş bilgilerini istemeyeceğini, toplamayacağını ve
      kullanmayacağını kabul eder.
    </div>
    <div>31. Denemapp Hizmetine girilmesini sağlayan doğrulama kodunun gizli tutulması
      Kullanıcının sorumluluğundadır.
    </div>
    <div>32. Kullanıcı, Denemapp Hizmetine ilişkin hiçbir yazılım veya içeriği değiştiremez,
      uyarlayamaz ve kopyalayamaz. Kullanıcı, herhangi bir başka internet sitesinin veya
      uygulamanın, Denemapp Hizmetiyle veya Denemapp’le ilişkili olduğuna dair yanlış
      izlenim verecek şekilde davranamaz, içerik oluşturamaz ve var olan içerikleri
      değiştiremez.
    </div>
    <div>33. Kullanıcı, solucan, virüs, casus yazılım, zararlı yazılım veya diğer her türlü zarar verici
      veya engelleyici kodu aktarmak dahil, Denemapp Hizmetini, sunucularını veya
      Denemapp Hizmetine bağlı ağları engelleyemez veya kesintiye uğratamaz. Kullanıcı,
      Denemapp sayfalarının kullanıcıların tarayıcılarında veya cihazlarında gösterilme ya
      da görüntülenme şeklini herhangi bir şekilde değiştiren veya engelleyen içerikler ya
      da kodlar ekleyemez.
    </div>
    <div>34. Kullanıcı, otomatik cihaz, kod, bot, örümcek, böcek veya kazıyıcı kullanmak dahil,
      ancak bunlarla sınırlı olmamak kaydıyla, Denemapp Hizmetinde yetkisiz yöntemlerle
      hesap oluşturamaz.
    </div>
    <div>35. Kullanıcı, başka bir Kullanıcının Denemapp Hizmetini kullanmasını kısıtlamaya
      çalışmayacağını ve bu Sözleşmenin veya diğer Denemapp koşullarının ihlal
      edilmesini teşvik etmeyeceğini taahhüt eder. Kullanıcı, başka bir Kullanıcı veya
      üçüncü bir kişi tarafından bu Sözleşmenin veya diğer Denemapp koşullarının ihlal
      edildiğini öğrendiğinde derhal Denemapp’i bilgilendireceğini kabul eder. Kullanıcı,
      söz konusu üçüncü kişilerin ihlaline olanak sağlaması ve/veya ihlalden haberdar
      olduktan sonra Denemapp’e haber vermemesi halinde ihlale iştirak etmiş sayılır.
    </div>
    <div>36. Denemapp her ay için yeni deneme koyabilir. Bu denemelerin kopyalanması, başka
      yerlerde paylaşılması kesinlikle yasaktır. Her türlü bilgi kopyalanması ve paylaşılması
      sorumluluğu kullanıcıya aittir.
    </div>
    <div>37. Kullanıcının herhangi bir şekilde Denemapp Hizmeti uygulamaları ve/veya internet
      sitesi üzerinden ve diğer başka bir yöntemle kendi iletişim bilgilerini (telefon
      numarası, e-posta adresi, sosyal ağ kullanıcı adı gibi) paylaşması yasaktır. Ayrıca,
      Kullanıcı diğer Kullanıcıların iletişim bilgilerini de, gerek Denemapp Hizmeti
      üzerinden gerekse diğer başka bir yöntemle istemeceğini veya başka herhangi bir
      şekilde elde etmeye çalışmayacağını taahhüt eder.
    </div>
    <div>38. Kullanıcının telefon numarasını ve telefonunu birlikte değiştirmesi durumunda eski
      Hesabına kayıtlı e-posta adresine gönderilecek olan mesaj üzerinden Kullanıcı, eski
      hesabını yeni hesabına taşıyabilir.
      İçerik İle İlgili Kullanıcının Sorumluluğu
    </div>
    <div>39. Kullanıcı tarafından Denemapp Hizmetine gönderilen içeriklerden tamamen
      Kullanıcı sorumludur.
    </div>
    <div>40. Kullanıcı, Denemapp Hizmetini hiçbir yasa dışı veya yetkisiz amaçla
      kullanmayacağını kabul eder. Kullanıcı, Denemapp Hizmetini kullanırken ve İçerik
      paylaşırken, telif hakkı yasaları dâhil, ancak bununla sınırlı kalmamak kaydıyla
      yürürlükteki tüm yasa, yönetmelik ve düzenlemelere uymayı kabul eder.
    </div>
    <div>41. Denemapp Hizmetindeki İçerikler otomatik yöntemlerle taranamaz, çekilemez, ön
      belleğe alınamaz veya başka bir şekilde (Denemapp’in açık izniyle bir arama motoru
      tarafından kullanılan standart arama motoru protokollerinin veya teknolojilerinin
      bulması haricinde) erişilemez.
    </div>
    <div>42. Denemapp, Denemapp Hizmetinde veya Denemapp Hizmeti aracılığıyla Kullanıcılar
      tarafından paylaşılan İçeriklerin mülkiyetini almaz. Bunun yerine, Kullanıcı, Gizlilik
      İlkelerinde bulunan ve 3. bölüm (“Bilgilerinizin Paylaşılması”), 4. bölüm (“Bilgilerinizi
      Nasıl Depoluyoruz”) ve 5. bölüm (“Bilgileriniz Hakkındaki Seçimleriniz”) dâhil, ancak
      bunlarla sınırlı olmamak kaydıyla, Denemapp Hizmetinin Gizlilik İlkesine tabi olmak
      üzere, Denemapp Hizmeti üzerinde veya aracılığıyla paylaştığı İçeriklerin kullanımı
      için Denemapp’e işbu belgeyle münhasır olmayan, bedeli tamamen ödenmiş ve
      telifsiz, devredilebilir, alt lisans verilebilir, uluslararası bir lisans tahsis eder.
    </div>
    <div>43. Bu durumda Kullanıcı: (i) Denemapp Hizmetinde veya Denemapp Hizmeti
      aracılığıyla paylaştığı İçeriklerin sahibi olduğunu veya işbu Sözleşmede belirtilen
      hakları ve lisansları verme hakkına sahip olduğunu; (ii) Denemapp Hizmetinde ve
      Denemapp Hizmeti aracılığıyla İçeriklerin paylaşılmasının ve kullanılmasının, gizlilik
      hakları, kişilik hakları, telif hakları, ticari marka ve/veya diğer fikri mülkiyet hakları
      dâhil, ancak bunlarla sınırlı olmamak kaydıyla, herhangi bir üçüncü tarafın haklarını
      irtikâp veya ihlal etmeyeceğini; (iii) Denemapp Hizmetinde veya Denemapp Hizmeti
      aracılığıyla paylaştığı İçerikler yüzünden ödenmesi gereken her tür telif ücretini,
      harçları ve diğer her tür bedeli ödemeyi kabul ettiğini ve (iv) bulunduğu yargı
      bölgesinde işbu Sözleşmeyi kabul etmek için yasal hak ve yetkiye sahip olduğunu
      beyan ve kabul eder.
    </div>
    <div>44. Kullanıcı, bir İçerik veya bilgi paylaştığında, Denemapp’e ve soruyu çözecek
      Eğitmenlere uygulamanın ilerideki diğer hizmetlerinde kullanımı için erişim ve
      kullanım izni vermiş olur.
    </div>
    <div>45. Denemapp Hizmetinin bir bölümü reklam gelirleriyle desteklenebilir ve Denemapp
      Hizmetinde reklamlar ve tanıtımlar gösterilebilir. Kullanıcı, Denemapp’in bu tür
      reklam ve tanıtımları Denemapp Hizmetine veya İçeriklere, İçeriklerin yanına veya
      İçeriklerle birlikte yerleştirebileceğini işbu belgede kabul eder. Bu tür reklam ve
      tanıtımların şekli, üslubu ve kapsamı bildirimsiz değiştirilebilir.
    </div>
    <div>46. Denemapp Hizmetinin Gizlilik İlkelerinde aksi belirtilmediği sürece, Kullanıcı ile
      Denemapp arasındaki hiçbir İçerik gizli ve tescilli olmayacaktır. Kullanıcının
      paylaştığı İçeriklerin kullanımı veya ifşası nedeniyle Denemapp hiçbir şekilde
      yükümlü olmayaktır ve bunların kullanılması, paylaşılması veya paylaşılmaması
      nedeniyle Denemapp’in herhangi bir sorumluluğu olmayacaktır. Kullanıcı,
      Denemapp’le ilişkisinin gizli, güvene dayalı veya başka bir şekilde özel bir ilişki
      olmadığını ve herhangi bir İçerik göndermeye karar vermesinin, İçeriklere ilişkin
      olması dâhil, Denemapp’i üçüncü kişilerden veya genel halka dâhil olan
      insanlarınkinden farklı bir konuma yerleştirmediğini kabul eder ve onaylar. İçeriklerin
      hiçbir bölümü Denemapp tarafından hiçbir güvenlik yükümlülüğüne tabi
      olmayacaktır ve Denemapp İçeriklerin hiçbir kullanımı veya ifşası için yükümlü
      olmayacaktır.
    </div>
    <div class="title">Denemapp İçerikleri ve Telif Hakları</div>
    <div>47. Denemapp Hizmeti’ne ilişkin veya bununla ilgili yazılımlar üzerindeki her türlü hak ve
      lisans Denemapp’e ait olup, Kullanıcıya Denemapp Hizmetine giriş sağlanması,
      hiçbir şekilde lisans verildiği anlamına gelmez. İşbu Sözleşme veya Denemapp ile
      Kullanıcı arasındaki hiçbir diğer sözleşme tahtında Kullanıcıya lisans verildiği
      anlamına gelebilecek bir hüküm bulunmadığı taraflarca kabul edilmiştir. 36, 37 ve
      39.maddelerde yer alan taahhütlere rağmen bu taahhütlere aykırı hareket eden ve
      herhangi bir tür ve şekilde Denemapp’in tazminat ödemesine veya sorumlu
      tutulmasına neden olan kullanıcı, Denemapp tarafından yazılı olarak talep edilmesini
      müteakip üç işgünü içerisinde kendisinden istenen miktarı faizi ile birlikte ödemeyi
      kabul ve taahhüt eder.
    </div>
    <div>48. Denemapp Hizmetinde, Denemapp’e ait veya Denemapp tarafından lisanslı veya
      Denemapp tarafından kullanma hakkı olan içerikler bulunabilir (“Denemapp
      İçerikleri“). Denemapp İçerikleri telif hakkı, ticari marka, patent, ticari sır ve diğer
      yasalar tarafından korunur ve Kullanıcı ile Denemapp arasında, Denemapp
      İçeriklerinin ve Denemapp Hizmetinin tüm hakları Denemapp’e aittir. Kullanıcı,
      Denemapp İçeriklerine dâhil olan veya eşlik eden hiçbir telif hakkı, ticari marka,
      hizmet markası veya diğer mülkiyet hakları bildirilerini kaldırmayacak,
      değiştirmeyecek veya gizlemeyecek ve Denemapp İçeriklerini yeniden
      kopyalamayacak, değiştirmeyecek, uyarlamayacak, türevlerini oluşturmayacak,
      çalmayacak/oynatmayacak, görüntülemeyecek, yayınlamayacak, dağıtmayacak,
      aktarmayacak, satmayacak, lisansını tahsis etmeyecek ve başka bir şekilde istismar
      etmeyecektir.
    </div>
    <div>49. Denemapp adı ve logosu Denemapp’in ticari markalarıdır ve Denemapp’ten önceden
      yazılı izin alınmadan tamamen veya kısmen kopyalanamaz, taklit edilemez ve
      kullanılamaz. Ayrıca, tüm sayfa başlıkları, özel grafikler, düğme simgeleri ve kodlar
      Denemapp’in hizmet markaları, ticari markaları ve/veya ticari takdim şekilleridir ve
      önceden Denemapp’ten yazılı izin alınmadan tamamen veya kısmen kopyalanamaz,
      taklit edilemez ve kullanılamaz.
    </div>
    <div>50. Bir açık kaynak lisansı altında açıkça izin verilmediği veya açık yazılı izin verilmediği
      takdirde, Kullanıcı, Denemapp yazılımını kısmen veya tamamen değiştiremez,
      türevlerini oluşturamaz, kaynak koda dönüştüremez veya bir başka şekilde kaynak
      kodu kopyalamaya çalışamaz.
    </div>
    <div>51. Kullanıcı, tek başına yazılım ürünü, uygulama veya tarayıcı eklentisi gibi Denemapp
      yazılımlarını indirir veya kullanırsa, zaman zaman yazılımı iyileştirmek, yükseltmek
      ve daha fazla geliştirmek için yazılım tarafından yükseltmeler, güncellemeler ve ek
      özellikler indireceğini ve bunları yükleyeceğini kabul eder.
    </div>
    <div>52. Denemapp, özellikle talep ettiği ve bazı özel koşul, şart ve gereksinimlerin geçerli
      olabileceği içerikler, bilgiler, fikirler, öneriler veya diğer malzemeler dışında hiçbir
      içeriği, bilgiyi, fikri, öneriyi veya diğer malzemeyi kabul etmez veya dikkate almaz.
      Bunun nedeni, fikirlerin Denemapp’in bağımsız olarak geliştirdiği veya geliştirmekte
      olduğu fikirlere benzer olması halinde herhangi bir yanlış anlaşılma olmasını
      önlemektir. Bu nedenle, Denemapp talep edilmemiş malzemeleri veya fikirleri kabul
      etmemektedir ve bu şekilde aktarılan malzeme veya fikirler için sorumluluk almaz.
      Bu ilkeye rağmen Kullanıcı, Denemapp’e içerik, bilgi, fikir, öneri veya başka
      malzemeler göndermesi halinde, Denemapp’in bu tür içerik, bilgi, fikir, öneri veya
      diğer malzemeleri, ürün ve hizmet geliştirme ve pazarlama dahil, ancak bunlarla
      sınırlı olmamak kaydıyla, herhangi bir amaç doğrultusunda Kullanıcıya karşı hiçbir
      yükümlülüğü olmadan ve herhangi bir ödeme yapmadan kullanmakta serbest
      olduğunu kabul eder.
      Ücret
    </div>
    <div>53. Abonelikler aboneliğin gerçekleştirildiği platformda belirtilen ücret ve kapsamda
      sunulmaktadır.
    </div>
    <div>54. Abonelik ücreti aylık olarak Kullanıcı’nın belirttiği ödeme yöntemiyle otomatik
      olarak alınmaktadır. Kullanıcı aboneliğini sonlandırmadığı sürece kullanıcının abone
      oluşunun ay dönümlerinde aboneliği aylık ücret karşılığında yenilecektir.
    </div>
    <div>55. Websitesi abonelik ücreti mevcut eğitim ve öğretim yılının sonuna kadar Kullanıcı’nın
      aboneliğe başladığı tarihte kabul ettiği miktarda devam eder. Abonelik ücreti yeni
      eğitim ve öğretim yılının Eylül ayı itibarıyla yeni ücretler üzerinden güncellenir.
    </div>
    <div>56. Websitesi üzerinden başlatılan abonelik paketinin farklı bir ücretli abonelik paketiyle
      değiştirilmesi durumunda, mevcut soru sorma hakları bir sonraki pakete devreder
      ancak devreden soru adedi bir önceki paketin bitiş süresine kadar geçerlidir.
    </div>
    <div>57. Denemapp Hizmetinin kullanılmasından kaynaklanan tüm veri ücretlerinden
      Kullanıcı sorumludur. Denemapp Hizmetlerinin Kullanıcı dışındaki kimseler
      tarafından kullanılması yasak olup, bunların her ne şekilde ve surette olursa olsun
      başkaları tarafından kullanılmasından doğacak her türlü hukuki, mali ve cezai
      sorumluluk Kullanıcıya aittir.
    </div>
    <div>58. Denemapp, yegâne takdir yetkisi altında Denemapp Hizmetinin veya Denemapp
      tarafından sunulan diğer hizmetlerin herhangi veya tüm yönleri için masrafları tesis
      etme, kaldırma ve/veya değiştirme hakkını saklı tutar. Denemapp paket ve/veya
      abonelik fiyatlarında herhangi bir zaman diliminde değişiklik yapabilir. Bu
      değişikliklerden geçmiş paket ve/veya abonelik alımları olumlu veya olumsuz
      etkilenmez. Ek olarak, belirli coğrafi alanlarda ve derslerde uygulanan masrafların,
      Denemapp Hizmetinin yüksek talep dönemlerinde önemli ölçüde artabileceği
      taraflarca kabul edilmiş ve mutabık kalınmıştır. Denemapp, Kullanıcının bilgisi
      dâhilinde olsa dahi Hesabı altında oluşan Kullanıcının sorumlu olacağı masraflar veya
      bunlarla ilgili miktarlar, uygulanabilecek masraflar hakkında Kullanıcıyı
      bilgilendirmek için makul çabayı gösterir. Denemapp zaman zaman aynı veya benzer
      Denemapp Hizmetleri için, promosyon teklifleri ve indirimlerle farklı masraflarla
      sonuçlanabilecek fırsatları belirli Kullanıcılara sağlar ve Kullanıcıya sunulduğu sürece,
      hizmetlerin kullanımı ya da Kullanıcıya uygulanan masrafların üzerinde hiç bir etkisi
      olmadan bu tip promosyon teklifleri ve indirimleri Kullanıcı kabul etmiş olur.
    </div>
    <div>59. Kullanıcı, promosyonların (i) hedef kitle ve amaç için ve yasal bir şekilde kullanılması
      gerektiğini; (ii) açıkça Denemapp tarafından izin verilmediği sürece,
      çoğaltılamayacağını, satılamayacağını veya herhangi bir şekilde aktarılamayacağını
      veya genel kamuya açık olmayacağını; (iii) Denemapp yükümlülük altına girmeden
      herhangi bir nedenle herhangi bir zamanda Denemapp tarafından devre dışı
      bırakılmış olabileceğini; (iv) Denemapp’in belli Promosyon Kodu için tesis ettiği
      belirli şartlara uygun olarak kullanılabileceğini (v) nakit için geçerli olmadığını ve (vi)
      kullanımdan önce sona erebileceğini kabul eder. Denemapp, Kullanıcının veya diğer
      bir Kullanıcının Promosyon kullanımında, promosyon kullanımı veya
      amortismanında yanlışlık, hile, yasadışı durum tespit eder veya promosyon
      koşullarının ve bu koşulların ihlaline inanır veya karar verirse kredileri ya da diğer
      özellikleri veya faydaları kısıtlama veya indirim yapma hakkını saklı tutar.
    </div>
    <div> 60. Dönemsel olarak Denemapp Hizmetine yeni üye olmuş kullanıcılara ücretsiz deneme
      hakkı tanınabilir. Denemapp’in, tanınan bu hakkı mevcut Kullanıcılara da yansıtma
      yükümlülüğü yoktur. Dönemsel olarak Denemapp Hizmeti Kullanıcılarının
      arkadaşlarını davet etmeleri ve davet edilen her arkadaşın Denemapp Hizmetine üye
      olması sonucunda ücretsiz deneme hakkı davet edilen Kullanıcıya tanınabilir.
      Arkadaşları davet etme karşılığı hak kazanılacak promosyonlar yalnızca Denemapp
      Hizmetinde SMS üzerinden yapılan davetler için geçerlidir ve 50 soru hakkıyla
      sınırlıdır. Dönemlik kampanyalardan geçmişte satın alınan paketlerle
      faydalanılamaz.
    </div>
    <div> 61. Hizmetleri kullanmak için gerekli veri ağ erişimini elde etmek Kullanıcının
      sorumluluğu altındadır. Mobil ağ verileri ve mesajlaşma oranları ve ücretleri
      Denemapp Hizmetlerine kablosuz özellikli cihazların kullanımı veya erişimi ile
      uygulanabilir. Denemapp Hizmetlerine ve ilaveten güncellemelere erişim ve kullanım
      için uyumlu donanım veya cihazların edinilmesi ve güncellenmesi Kullanıcının
      sorumluğu altındadır. Denemapp, Denemapp Hizmetlerinin veya bunun herhangi bir
      kısmının, herhangi bir donanım veya cihazda çalışacağını garanti etmez. Ek olarak
      Denemapp Hizmetleri internet veya elektronik haberleşmelerin kullanımından
      doğabilecek arızalara ve gecikmelere tabi olabilir. Denemapp söz konusu nedenlerle
      yaşanan gecikmelerden sorumlu olmayacaktır.
    </div>
    <div>62. Denemapp’in kontrolü dışında, internet sağlayıcısı veya diğer teknik sebeplerle
      Denemapp Hizmeti’ne erişiminde aksaklıklar olması halinde, Denemapp en kısa
      sürede söz konusu aksaklıkları çözmeyi hedeflemektedir, ancak bu sebeplerle
      Denemapp Hizmetlerine erişimin aksaması nedeniyle Denemapp’in herhangi bir
      sorumluluğu olmayacağını Kullanıcı kabul eder.
      Hizmetin Sonlandırılması ve Fesih
    </div>
    <div>63. Denemapp, herhangi bir bildirimde bulunmaksızın Denemapp Hizmetini
      sonlandırma, erişimi değiştirme hakkını saklı tutmaktadır. Denemapp Hizmetinin
      sonlandırılması durumunda Denemapp’in sorumluluğu Kullanıcının paketinde
      kullanmadığı miktarla sınırlı olduğu, başkaca hiç bir hukuki, mali ve cezai
      sorumluluğu olmadığı Kullanıcı tarafından kabul ve ikrar olunmuştur.
    </div>
    <div> 64. Kullanıcı, Hesabını sonlandırmak istediği takdirde, support@denemapp.com
      adresine talebini açıklayan bir e-posta atarak Denemapp Hizmeti Hesabını
      sonlandırabilir. Denemapp Hizmetine erişimin sonlandırılması, fotoğraflara,
      yorumlara, sorulara iletilen cevaplara ve diğer verilere Kullanıcının Hesabı üzerinden
      erişimin kapatılması anlamına gelmektedir, ancak bu bilgiler ve veriler Denemapp
      Hizmetinde kalmaya, ileride farklı hizmetler için Kullanıcı kimliğini açığa
      çıkartmayacak şekilde kullanılmaya ve görünmeye devam edebilir.
    </div>
    <div> 65. Sözleşmenin herhangi bir veya birden fazla hükmünün ihlali, Denemapp’in tamamen
      kendi takdirine bağlı olarak, Kullanıcı Hesabının kapatılmasıyla sonuçlanabilir.
      Kullanıcının, Denemapp Hizmetinde paylaştığı İçeriklerin Denemapp’in
      sorumluluğunda olmadığını ve olamayacağını ve Denemapp Hizmetini kullanırken
      karşı karşıya kalabilecek risklerin tamamen Kullanıcının sorumluluğunda olduğunu
      Kullanıcı kabul eder. Kullanıcının, işbu Sözleşmenin özünü veya metnini herhangi bir
      şekilde ihlal etmesi ya da Denemapp’i bir risk veya başka bir muhtemel hukuki
      yaptırıma maruz bırakması durumunda, Denemapp, Denemapp Hizmetini
      Kullanıcıya sunmaya kısmi olarak ya da bütünüyle son verebilir.
    </div>
    <div>66. Hesap kapatıldıktan sonra, işbu Sözleşmede verilen tüm hakların geçerliliği derhal
      sona erer.
    </div>
    <div> 67. Denemapp’in herhangi birinin Denemapp Hizmetine erişmesini istediği zaman
      reddetme hakkı saklıdır.
      Sözleşmenin İhlali
    </div>
    <div> 68. Denemapp, Kullanıcının işbu Sözleşmenin herhangi bir veya birden fazla hükmünü
      ihlal ettiğini tamamen kendi takdirine bağlı olarak belirlediğinde, İçerikleri veya
      İçeriklerin bulunduğu Hesapları kaldırabilir, düzenleyebilir, engelleyebilir ve/veya
      izleyebilir, Denemapp’in bu yönde herhangi bir yükümlülüğü olmadığını, Kullanıcı
      kabul eder. Kullanıcının ihlali sonucu Denemapp’in ve/veya üçüncü şahısların zarara
      uğraması halinde Kullanıcı, hukuki ve cezai sorumluluğun kendisine ait olduğunu ve
      zararı da tazmin edeceğini kabul ve taahhüt eder.
      Sorumluluk
    </div>
    <div> 69. Denemapp Eğitmenlerin uygunluk, doğruluk veya yetkinliğini garanti etmez.
      Eğitmenlerin Kullanıcının ihtiyaçlarını ve beklentilerini karşılayacağını belirlemek
      yalnızca Kullanıcının sorumluluğu altındadır. Denemapp Kullanıcılar ve Eğitmenler
      arasındaki uyuşmazlıklara dahil olmayacaktır. Denemapp Hizmetini kullanarak
      üçüncü şahıs tedarikçilerini kapsayan potansiyel güvensizlik, saldırı, küçüklere zararlı
      olması veya başka bir şekilde Eğitmenler tarafından hizmetler kullanılarak
      düzenlenen veya planlanan durumların riski ve takdiri Kullanıcıya ait olmak üzere,
      Kullanıcı tarafından kabul edilmiştir. Denemapp’in Eğitmenler ve Kullanıcılar
      arasındaki ilişkilere veya işlemlere bağlı veya mütevellit durumlarla ilgili sorumluluğu
      bulunmaz.
    </div>
    <div> 70. Denemapp Hizmetinden alınan iletişimlerden üçüncü taraf internet sitelerine veya
      özelliklerine bağlantılar verilebilir. Denemapp Hizmetindeki görüntülerde veya
      yorumlarda da üçüncü taraf internet sitelerine veya özelliklerine bağlantılar
      bulunabilir. Denemapp Hizmetindeki işlevler Denemapp Hizmetiyle üçüncü taraf
      internet siteleri veya özellikleri arasında etkileşimler olmasına izin verebilir. Buna
      Denemapp Hizmetini ve Denemapp Hizmetindeki Kullanıcı Hesabını üçüncü taraf
      internet sitelerine veya özelliklerine bağlayan uygulamalar dâhildir. Örneğin,
      Denemapp Hizmetinde bulunan bir özellik, Kullanıcının Denemapp Hizmetinden
      İçerik paylaşmasına veya İçerikleri bir üçüncü tarafla paylaşmasına olanak
      sağlayabilir ve bunlar söz konusu üçüncü tarafın hizmetinde veya uygulamasında
      herkese açık olarak paylaşılabilir. Bu işlevi kullanmak için genellikle Kullanıcının
      Hesabına üçüncü taraf hizmetinden giriş yapması gerekir ve bu işlemi yaparken karşı
      karşıya kalınabilecek riskler tamamen Kullanıcının sorumluluğundadır. Denemapp’in
      bu üçüncü taraf internet hizmetleri veya içerikleri üzerinde kontrolü yoktur. Kullanıcı,
      Denemapp’in bu tür üçüncü taraf hizmetlerinden veya özelliklerinden hiçbir şekilde
      sorumlu veya yükümlü olmadığını açıkça kabul eder. Denemapp Hizmeti aracılığıyla
      bulunan üçüncü taraflarla aradaki iletişim ve ticari işlemler tamamen Kullanıcı ile
      üçüncü tarafın arasındadır. Kullanıcı, Denemapp Hizmetini veya Denemapp
      Hizmetindeki Hesabı üçüncü taraf hizmetleriyle bağlayan uygulamaları (her biri
      “Uygulama” olarak anılır), tamamen kendi takdirine bağlı olarak ve karşı karşıya
      kalabileceği risklerin tüm sorumluluğunu alarak kullanmayı seçebilir ve söz konusu
      Uygulama Denemapp Hizmetindeki Kullanıcı Hesabıyla etkileşimde bulunabilir,
      bağlantı kurabilir veya Hesap bilgilerini toplayabilir ve/veya alabilir. Kullanıcı, bu tür
      Uygulamaları kullanarak, aşağıdakileri kabul etmiş olur: (i) Bilgi paylaşmak için bir
      Uygulama kullanılırsa, Denemapp Hizmetindeki Hesapla ilgili bilgiler paylaşılabilir,
      (ii) bir Uygulamanın kullanılması, kimliği belirleyen bilgilerin, Denemapp bu bilgileri
      sağlamamış olsa bile, herkese açık biçimde ifşa edilmesine ve/veya Kullanıcı ile
      ilişkilendirilmesine neden olabilir ve (iii) bir Uygulamanın kullanılması tamamen
      Kullanıcının tercihine bağlıdır ve bundan kaynaklanabilecek riskler tamamen
      Kullanıcının sorumluluğundadır ve Kullanıcının Denemapp’i Uygulamaya ilişkin
      hareketlerden doğacak zararlara karşı tazmin etmesi gerekebilir.
      71. Denemapp’in amacı Denemapp Hizmetinin olabildiğince kullanılabilir olmasını
      sağlamak olsa da, programlanmış bakım veya yükseltme, acil durum onarımları veya
      telekomünikasyon bağlantılarının ve/veya ekipmanlarının arızalanması nedenleri
      dâhil, ancak bunlarla sınırlı olmamak kaydıyla, Denemapp Hizmeti kesintiye
      uğrayabilir. Ayrıca Denemapp’in önceden bildirimde bulunmaksızın Denemapp
      Hizmetindeki her türlü içeriği herhangi bir nedenle kaldırma hakkı saklıdır.
      Denemapp Hizmetinden kaldırılan içerikler, bazı yasal yükümlülüklere uymak amacı
      dâhil, ancak bununla sınırlı olmamak kaydıyla Denemapp tarafından depolanmaya
      devam edebilir. Buna bağlı olarak Denemapp, Kullanıcıya kendi İçeriklerini
      yedeklemesini tavsiye eder. Başka bir deyişle, Denemapp Hizmeti bir yedekleme
      hizmeti değildir ve Denemapp Hizmetinden İçerikleri yedeklemesi veya depolaması
      beklenemez. Denemapp Hizmetlerinin değiştirilmesi, durdurulması ve
      sonlandırılması veya İçeriklerin kaybedilmesi durumunda Denemapp’in Kullanıcıya
      karşı herhangi bir yükümlülüğü yoktur. Kullanıcı, ayrıca İnternetin güvenlik ihlallerine
      tabi olabileceğini ve İçerik veya başka bilgileri göndermenin güvenli
      olmayabileceğini de kabul eder.
      Garanti Reddi
    </div>
    <div> 72. Denemapp İçerikleri dahil ancak bunlarla sınırlı olmamak üzere, Denemapp Hizmeti
      “olduğu gibi” ve “tüm hatalarıyla” sunulmaktadır. Yasaların izin verdiği en geniş
      ölçüde, Denemapp Taraflarından hiçbiri, (a) Denemapp Hizmeti, (b) Denemapp
      İçerikleri, (c) Kullanici İçerikleri veya (d) Denemapp’e veya Denemapp Hizmeti
      aracılığıyla bilgi aktarılmasına ilişkin güvenlik ile ilgili olarak açık veya zımni hiçbir
      beyan veya garanti veya destek vermemektedir. Ayrıca Denemapp Tarafları işbu
      sözleşmede, pazarlanabilirlik, özel bir amaca uygun bulunma, ihlal etmeme, zapta
      karşı teminat, özel, ticaret, müdahalesiz kullanım, sistem entegrasyonu ve bilgisayar
      virüsü içermeme garantileri dâhil, ancak bunlarla sınırlı olmamak kaydıyla açık veya
      zımni her tür garantiyi reddetmektedir.
    </div>
    <div> 73. Denemapp Tarafları, Denemapp Hizmetinin hatasız veya kesintisiz çalışacağını,
      kusurların düzeltileceğini veya Denemapp Hizmetini sunan Denemapp Hizmeti veya
      sunucusunun virüsler dâhil, ancak bunlarla sınırlı olmamak kaydıyla, zararlı bileşenler
      içermediğini beyan veya garanti etmez. Denemapp Tarafları, Denemapp Hizmetinde
      bulunan bilgilerin (talimatlar dâhil) doğru, eksiksiz veya kullanışlı olduğuna dair
      hiçbir beyanda bulunmaz ve garanti vermez. Kullanıcı, Denemapp Hizmetinin
      kullanılmasından kaynaklanan risklerin kendisinin sorumluluğunda olduğunu kabul
      eder. Denemapp Tarafları, Denemapp Hizmetinin kullanılmasının herhangi bir yargı
      bölgesinde yasal olduğu garantisini vermez ve Denemapp Tarafları bu tür garantileri
      özellikle reddeder.
    </div>
    <div> 74. Kullanıcı, Denemapp Hizmetine erişerek veya Denemapp Hizmetini kullanarak,
      Denemapp Hizmetine eriştiği veya Denemapp Hizmetini kullandığı tüm yargı
      bölgelerinde Denemapp Hizmeti vasıtasıyla gerçekleştirmiş olduğu hareketlerin
      yasalara uygun olduğunu beyan ve garanti eder.
    </div>
    <div> 75. Denemapp Tarafları İçerikleri desteklemez ve İçeriklere dayanan veya İçeriklerden
      kaynaklanan hiçbir kayıp, hasar (gerçek, sonuç olarak ortaya çıkan, cezai veya diğer
      her türlü), tazminat, yükümlülük veya diğer her tür sebepten doğan sorumluluk veya
      yükümlülükleri özellikle reddeder.
      Sorumluluğun Sınırlandırılması; Feragatname
    </div>
    <div> 76. Denemapp’in hiçbir koşulda Kullanıcıya karşı doğrudan veya dolaylı olarak (a)
      Denemapp Hizmeti; (b) Denemapp İçerikleri; (c) Kullanıcı İçerikleri; (d) Denemapp
      Hizmetinin kullanılması, kullanılmaması veya Denemapp Hizmetinin performansı; (e)
      Denemapp Taraflarının veya emniyet birimlerinin Kullanıcının veya başka bir tarafın
      Denemapp Hizmetini kullanmasına ilişkin olarak yürüttüğü bir soruşturmayla
      bağlantılı her tür dava; (f) telif hakkı veya diğer fikri mülkiyet hak sahipleriyle
      bağlantılı her tür dava; (g) Denemapp Hizmetinin çalışmasındaki her tür hata veya
      kusur veya (h) herhangi bir Kullanıcının bilgisayarı, mobil cihazı veya diğer ekipman
      veya teknolojisinde meydana gelen kar kaybı, itibar kaybı, veri kaybı, işin durması,
      sonuçlarda hata veya bilgisayar arızası veya hatalı çalışması dahil ve bunlarla sınırlı
      olmamak kaydıyla herhangi bir güvenlik ihlalinden veya herhangi bir virüs, böcek,
      kurcalama, sahtekarlık, hata, kusur, ihmal, kesinti, arıza, çalışma veya aktarma
      gecikmeleri, bilgisayar hattı veya ağ arızaları veya diğer her türlü teknik hatadan
      veya diğer hatalı çalışmalardan kaynaklanan her tür hasar dahil, ancak bunlarla sınırlı
      olmamak kaydıyla, bunlarla ilgili olan hiçbir kayıp veya hasar için yükümlülüğü
      yoktur.
    </div>
    <div> 77. Denemapp’in hareketleri veya kusurlarından kaynaklanan hasar veya kayıp olması
      halinde, varsa, Kullanıcının uğradığı zararın telafi edilemeyeceğini veya Denemapp
      Taraflarına ait veya onlar tarafından kontrol edilen hiçbir internet sitesi, uygulama,
      hizmet, mülkiyet, ürün veya diğer içerikten faydalanmayı engelleyen bir mahkeme
      emri almaya hak kazanma için yeterli olmadığını ve Denemapp Taraflarına ait veya
      onlar tarafından kontrol edilen hiçbir internet sitesi, uygulama, mülkiyet, ürün,
      hizmet veya diğer içeriğin geliştirilmesini, üretimini, dağıtımını, reklamının
      yapılmasını, gösterilmesini veya bunlardan faydalanmayı engelleme veya kısıtlama
      hakkının olmayacağını Kullanıcı kabul eder.
    </div>
    <div> 78. Üçüncü şahıslara ait faaliyetler, içerik, bilgi veya verilerden Denemapp sorumlu
      değildir. Kullanıcı, Denemapp’i, direktörlerini, çalışanlarını ve aracılarını, bilinen veya
      bilinmeyen, herhangi bir üçüncü şahsa karşı açmış olduğu bir dava konusundan
      doğan veya bu tür bir dava konusuyla ilişkili herhangi bir talepten ve hasardan ibra
      ettiğini kabul eder.
      Tazminat
    </div>
    <div> 79. Kullanıcı (i) Kullanıcı İçeriği veya Hizmet kullanımına erişimi; (ii) Bu Sözleşmenin ihlali
      veya iddia edilen ihlali; (iii) fikri mülkiyet hakları, kişilik, gizlilik veya mülkiyet hakları
      dahil, ancak bunlarla sınırlı olmamak kaydıyla, herhangi bir üçüncü şahsın hakkının
      ihlali; (iv) her tür düzenleyici, idari ve yasama kurumu dahil, ancak bunlarla sınırlı
      olmamak kaydıyla, her tür hükümet ve hükümet benzeri kurumun herhangi bir
      kanun, kural, düzenleme, yasa, tüzük, hüküm veya talimatını ihlali veya (v) Kullanıcı
      tarafından verilen herhangi bir yalan beyan ve bunlarla sınırlı olmamak kaydıyla
      herhangi bir davranışı nedeniyle veya bununla ilişkili olarak Denemapp’in karşı
      karşıya kalabileceği her türlü tazminat ve taleplere karşı Denemapp’in zararını
      tazmin edeceğini kabul eder. Böyle bir talep karşısında, Kullanıcı, Denemapp’i
      savunacağını (Denemapp’in talebi üzerine), beri kılacağını ve yukarıdakilerden
      herhangi birine bağlı olarak (Denemapp Hizmetinde doğrudan Kullanıcının
      gerçekleştirdiği hareketler veya Kullanıcı adına gerçekleştirilen hareketler dahil)
      meydana gelen veya bunlarla başka bir şekilde ilişkili olabilecek her tür makul
      avukatlık ücretleri ve maliyetleri dahil, ancak bunlarla sınırlı olmamak kaydıyla,
      oluşabilecek her tür tazminat, yükümlülük, hasar, kayıp, masrafı tazmin edeceğini
      kabul eder.
    </div>
    <div> 80. Kullanıcı, her türlü iddianın savunmasında Denemapp’in talep ettiği şekilde işbirliği
      yapmayı taahhüt eder. Denemapp, Kullanıcının tazmin etmesine tabi olan her tür
      konunun münhasır savunmasını ve kontrolünü yürütme hakkını saklı tutar ve
      Kullanıcı da önceden Denemapp’ten yazılı izin almadan hiçbir tazminat talebini
      karara bağlamayacağını kabul eder.
      Hak Taleplerinde Zaman Sınırlaması
    </div>
    <div> 81. Kullanıcı, Denemapp’le arasındaki ilişkiden kaynaklanan her tür hak talebinin, ortaya
      çıktığı tarihten itibaren bir yıl içinde yapılması gerektiğini, aksi takdirde hak talebini
      kanunen yapamayacağını kabul eder.
      Geçerli Yasa ve Yargılama Yeri
    </div>
    <div> 82. İşbu Kullanım Koşullarının, milletlerarası hukuk prensipleri dikkate alınmaksızın,
      Türkiye Cumhuriyeti yasalarına göre yönetileceği, yorumlanacağı ve
      sonuçlandırılacağı, Denemapp’in her türlü kayıtlarının 6100 Sayılı Hukuk
      Muhakemeleri Kanununun 222. Maddesi anlamında kesin delil teşkil edeceği, her
      türlü uyuşmazlık ve ihtilaf vukuunda İstanbul Mahkemeleri ve İcra Dairelerinin yetkili
      olacağı taraflarca beyan ve kabul edilmiştir.
    </div>
  </div>
</div>
