import { Component, OnInit } from '@angular/core';

const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.denemapp&gl=TR';
const APP_STORE_LINK = 'https://apps.apple.com/bz/app/denemapp/id1517223927' ;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public goToPlayStore(): void{
    window.location.href = PLAY_STORE_LINK;
  }
  public goToAppStore(): void{
    window.location.href = APP_STORE_LINK;
  }

}
