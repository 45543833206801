<div class="footer-container">
  <div class="company-info">
    <div class="name">
      Kalkülüs Eğitim ve Bilişim Teknolojileri Sanayi ve Ticaret LTD ŞTİ
    </div>
    <div class="sub-info">
     Tel:  +90 546 286 47 66
    </div>
    <div class="sub-info">
     e-mail: info@denemapp.com
    </div>
  </div>
  <div class="agreements">
    <a mat-button [routerLink]="'/about-us'" class="navigation-link"> Hakkımızda </a>
    <a mat-button [routerLink]="'/agreement'" class="navigation-link"> Kullanıcı Sözleşmesi </a>
    <a mat-button [routerLink]="'/privacy'" class="navigation-link"> KVKK & Gizlilik Sözleşmesi </a>
  </div>
  <div class="iyzico-logos">
    <img class="iyzico-serit" src="../../assets/iyzico-serit.png" />

  </div>
</div>
