<div class="home-container">

  <div class="content-section">

    <div class="info-area">
      <div class="logo-section">
        <div class="logo">
          <img class="logo-image" src="../../assets/logo.png"/>
        </div>
        <div class="logo-text">
          denemapp
        </div>
      </div>
      <div class="explanation-text">
        Türkiye geneli orjinal LGS - TYT - AYT denemeleri ile sıralamanı görebileceğin bu ugulamadır. Optik form uğraşı
        olmadan, sınav bitimi anında açıklanan sonucun ile karnene bakıp eksik konularını görebilirsin. 2021 LGS ve YKS
        sınavlarına sen de hazır hissedebilirsin
      </div>

      <div class="buttons">
        <div class="button app-store" (click)="goToPlayStore()"></div>
        <div class="button play-store" (click)="goToAppStore()"></div>
      </div>
      <div class="contact">
        Görüş ve önerileriniz için bize
        <a class="contact-email" href="mailto:info@denemapp.com"> info@denemapp.com </a>
        adresimizden ulaşabilirsiniz.
      </div>

    </div>
    <div class="screen-area">
      <img class="screenshot" src="../../assets/screenshot.png"/>
    </div>
  </div>
</div>
