<div class="navigation-bar-container">

      <span class="menu-spacer"></span>
      <div class="naviagition-buttons">
        <a mat-button [routerLink]="'/home'" class="navigation-link"> Anasayfa </a>
        <a mat-button [routerLink]="'/agreement'" class="navigation-link"> Kullanıcı Sözleşmesi </a>
        <a mat-button [routerLink]="'/privacy'" class="navigation-link"> KVKK & Gizlilik Sözleşmesi </a>
      </div>


</div>
