<div class="privacy-container">
  <div class="privacy-header"> Denemapp Gizlilik Sözleşmesi</div>

  <div class="privacy-text">
    <div class="title"> Gizlilik Sözleşmesi</div>
    Denemapp’a hoş geldiniz (“Denemapp,” “biz,” “bize,” “bizi” veya “bizim” olarak
    anılacaktır). Denemapp, sizlere online ortamda deneme çözmenize imkan sağlar.
    Uygulama içersinde bulunan herhangi bir denemeyi çözüp sonuçlarınızı görebilirsiniz.

    Gizlilik İlkemizde, bizim ve birlikte çalıştığımız şirketlerden mobil hizmetlerimize,
    internet sitemize ve Denemapp hizmetlerine veya bunlarla bağlantılı olarak sunulan tüm
    yazılımlara (tümü “Denemapp Hizmeti” olarak anılır) ilişkin bilgileri nasıl topladığımız,
    kullandığımız, paylaştığımız ve koruduğumuz ve bilgilerinizin toplanması ve
    kullanılmasına ilişkin seçimleriniz hakkında açıklamalar bulunmaktadır.

    Gönderdiğiniz bilgiler değerlendirilerek size daha iyi hizmet vermek için kullanılabilir,
    bilgilerinizi isminizi ifşa etmeden mevcut ve gelecekteki hizmetlerimizde kullanabiliriz.

    Gizlilik İlkemiz, Denemapp Hizmetine erişen tüm ziyaretçiler, öğrenciler ve diğer kişiler
    (“Kullanıcılar”) için geçerlidir.

    <div class="title">1. TOPLADIĞIMIZ BİLGİLER</div>
    Aşağıda belirtilen bilgileri topluyoruz. Bize doğrudan verdiğiniz bilgiler:
    • Bir Denemapp hesabına kaydolurken girdiğiniz adınız, soyadınız, cep telefonu
    numaranız, e-posta adresiniz, doğum tarihiniz, sınıfınız ve benzeri bilgiler.
    • Sizinle Denemapp arasındaki iletişimler. Örneğin, size hesabınızı doğrulama,
    Denemapp Hizmetindeki özelliklerde yapılan değişiklikler/güncellemeler,
    çözümlerle ilgili geri dönüşler, teknik ve güvenlik bildirimleri gibi Denemapp
    Hizmetine ilişkin kısa mesajlar, e-postalar ve doğrulama amaçlı mesajlar (SMS)
    gönderebiliriz.
    Analiz bilgileri:
    • Denemapp Hizmetimizdeki trafiği ve kullanım eğilimlerini ölçmek için üçüncü
    taraf analiz araçları kullanıyoruz. Bu araçlar, uygulamada gerçekleştirdiğiniz
    aktiviteleri, aktivitelerin süresini ve sıklığını ve Denemapp Hizmetimizi
    iyileştirmemize yardımcı olan diğer bilgiler dahil olmak üzere, cihazınız veya
    Denemapp Hizmetimiz tarafından gönderilen bilgileri toplar. Bu analiz bilgilerini,
    herhangi bir Kullanıcının kimliğinin belirlenmesinde kullanılamamaları için diğer
    Kullanıcılardan gelen analiz bilgileriyle birlikte toplayıp kullanıyoruz.
    Kullanıcı İçerikleri’nin Kullanımı:
    • Kullanıcı İçerikleriyle ilgili Denemapp hiçbir sorumluluk kabul etmemektedir.
    Buna rağmen kullanıcıların gönderdiği soruların ve içeriklerin herhangi bir
    3.şahsın fikri haklarını ihlal etmesi durumunda ve/veya Denemapp’in herhangi bir
    adli-idari makam tarafından sorumlu tutulması söz konusu olduğunda, ödenmesi
    gereken tüm bedel kullanıcıdan tazmin ve tahsil edilecektir.
    Çerezler ve benzer teknolojiler:
    • Denemapp Hizmetini ziyaret ettiğinizde, Denemapp’i nasıl kullandığınız hakkında
    bilgi toplamak ve size özellikler sunmak için çerezler ve piksel, web işaretçisi veya
    yerel depolama gibi benzer teknolojileri kullanabiliriz.
    • Reklam verenlerden veya başka ortaklardan cihazlarınıza reklam veya hizmetler
    sunmalarını isteyebiliriz. Bunlar da bizim veya üçüncü tarafın yerleştirdiği
    çerezleri veya benzer teknolojileri kullanabilir.
    Günlük dosyası bilgileri:
    • Bir internet sayfasına veya uygulamaya erişmek (yani gitmek) istediğinizde
    tarayıcınız otomatik olarak günlük dosyası bilgilerini raporlar. Bu bilgiler internet
    sayfasının veya uygulamanın içerikleri tarayıcınıza veya cihazınıza indirildiğinde
    de verilir.
    • Denemapp Hizmetimizi kullandığınızda, sunucularımız internetteki talebiniz,
    İnternet Protokolü (“IP”) adresi, tarayıcı türü, yönlendirme / çıkış sayfaları ve
    internet adresleri (URL), tıklama sayısı ve Denemapp Hizmetindeki bağlantılarla
    nasıl etkileşimde bulunduğunuz, domain adları, yönlendirildiğiniz sayfalar,
    görüntülediğiniz sayfalar ve bunun gibi diğer bilgileri de içeren günlük dosyası
    bilgilerini otomatik olarak kaydeder. Kullanıcılarımıza gönderilen e-postalardan da
    benzer bilgiler toplayabiliriz. Bu bilgiler, hangi e-postaların açıldığını ve alıcıların
    hangi bağlantılara tıkladığını takip etmemize yardımcı olur. Topladığımız bilgiler
    daha doğru raporlama yapılabilmesini ve Denemapp Hizmetinin iyileştirilmesini
    sağlar.
    Cihaz bilgileri: Verdiğiniz izinlere bağlı olarak, Hizmetlerimizi yüklediğiniz ya da bunlara
    eriştiğiniz bilgisayarlar, telefonlar veya diğer cihazlardan bu cihazlar hakkındaki bilgileri
    toplarız. Farklı cihazlarınızdan topladığımız bilgileri birbirleriyle ilişkilendirebiliriz. Bu,
    tüm cihazlarınızda tutarlı Hizmetler sağlamamıza yardımcı olur. Topladığımız cihaz
    bilgilerine bazı örnekler şunlardır:
    • Denemapp Hizmetimize erişmek için tablet veya telefon gibi bir mobil cihaz
    kullandığınızda, cihazınızdaki “cihaz tanımlayıcılara” erişebilir, bunları
    toplayabilir, izleyebilir, cihazınızda depolayabilir ve/veya uzaktan bir veya daha
    fazla “cihaz tanımlayıcı” depolayabiliriz. Cihaz tanımlayıcılar, mobil cihazınızda
    depolanan veya mobil cihazınızla ilişkilendirilen ve mobil cihazınızı benzersiz bir
    şekilde tanımlayan küçük veri dosyalarıdır veya benzer veri yapılarıdır. Cihaz
    tanımlayıcı, cihazın donanımına, işletim sistemine veya diğer yazılımlara bağlı
    olarak depolanan veriler veya Denemapp’in cihaza gönderdiği veriler olabilir.
    • Bir cihaz tanımlayıcı, bize veya üçüncü taraf ortaklarımıza Denemapp’da nasıl
    gezindiğiniz ve Denemapp Hizmetini nasıl kullandığınız hakkında bilgiler verebilir
    ve bizim veya başkalarının raporlar, kişiselleştirilmiş içerikler ve reklamlar
    sunmasına yardımcı olabilir. Cihaz tanımlayıcıların kullanılabilirliğinin bozulması
    veya engellenmesi halinde, Denemapp Hizmetindeki bazı özellikler düzgün
    çalışmayabilir.
    • İletişim sistemi, donanım versiyonu, cihaz ayarları, dosya ve yazılım isimleri ve
    türleri, pil ve sinyal gücü, cihaz tanımlayıcıları gibi özellikler.
    • Belirli coğrafi konumlar dahil olmak üzere cihaz konumları (örneğin GPS,
    Bluetooth veya WiFi sinyalleri yoluyla).
    • Cep telefonu operatörünüzün veya internet servis sağlayıcınızın adı, tarayıcı türü,
    dil ve zaman dilimi, cep telefonu numarası ve IP adresi gibi bağlantı bilgileri.
    Ödemelerle ilgili bilgiler
    • Hizmetlerimizi alışveriş veya mali işlemler yapmak için kullanırsanız (Denemapp
    üzerinden bir paket satın almak gibi), bu alışveriş veya işlem hakkındaki bilgileri
    toplarız. Kredi kartı ya da banka kartı numaranız veya başka kart bilgisi gibi ödeme
    bilgileri, diğer hesap ve kimlik doğrulama bilgilerinin yanı sıra fatura, gönderim ve
    iletişim bilgileri bunlara dahildir.

    <div class="title">2. BİLGİLERİNİZİ NASIL KULLANIYORUZ</div>
    Bilgilerin bu Gizlilik İlkesinde anlattığımız belirli kullanımlarına ek olarak, aldığımız
    bilgileri aşağıdaki amaçlarla da kullanabiliriz:
    • Giriş yaptıktan sonra bilgilerinize etkili bir şekilde erişmenize yardımcı olmak
    • Denemapp Hizmetini ziyaretiniz sırasında veya bir sonraki ziyaretinizde
    bilgilerinizi yeniden girmenize gerek kalmaması için bilgilerinizi hatırlamak
    • Size ve başkalarına çevrimiçi reklamlar veya diğer pazarlama biçimleri de dahil
    kişiselleştirilmiş içerikler ve bilgiler sunmak
    • Pazarlama iletişimleri göndermek, sizinle Hizmetlerimiz hakkında iletişime
    geçmek ve ilke ve kurallarımızı size bildirmek
    • Bizimle iletişime geçtiğinizde size yanıt vermek
    • Denemapp Hizmetimizin etkinliliğini sağlamak, iyileştirmek, test etmek ve
    izlemek
    • Elimizdeki bilgileri, size Denemapp Hizmetleri içinde ve dışında ilginizi çekecek
    reklamlar gösterebilmek ve reklam ve hizmetlerin etki ve erişimini ölçebilmek
    amacıyla, reklam ve ölçüm sistemlerimizi geliştirmek
    • Yeni ürünler ve özellikler geliştirip test etmek
    • Elimizdeki bilgileri, hesapları ve hareketleri doğrulamaya yardımcı olmak ve
    Hizmetlerimiz içinde ve dışında emniyet ve güvenlik sağlamak (örneğin şüpheli
    hareketleri veya koşul ya da ilkelerimizin ihlallerini inceleyerek)
    • Toplam ziyaretçi sayısı, trafik ve demografik eğilimler gibi ölçümleri izlemek
    • Teknoloji sorunlarını teşhis etmek veya düzeltmek
    • Cihazınızdaki Denemapp uygulamasını otomatik olarak güncellemek
    <div class="title">3. BİLGİLERİNİZİN PAYLAŞILMASI</div>
    Bilgilerinizi bu İlkede belirtilen durumlar haricinde onayınızı almadan Denemapp
    dışındaki üçüncü taraflara kiralamayız veya satmayız. Bilgilerinizi paylaşabileceğimiz
    taraflar:
    • Size Denemapp Hizmetini sunmamıza yardımcı olan üçüncü taraf kuruluşlarla
    (“Hizmet Sağlayıcılar”) bilgilerinizi ve çerezler, günlük dosyaları ve cihaz
    tanımlayıcıları gibi araçlardan aldığımız bilgileri ve konum verilerini de
    paylaşabiliriz. Hizmet Sağlayıcılarımız bilgilerinize Denemapp Hizmetini sunmak
    için gereken ölçüde ve makul gizlilik koşullarına uygun olarak erişebilecektir.
    • Çerez verileri gibi bazı bilgileri üçüncü taraf reklam veren ortaklarımızla da
    paylaşabiliriz. Bu bilgiler, üçüncü taraf reklam ağlarının en çok ilginizi çekeceğine
    inandıkları hedeflemeli reklamlar yayınlamalarına olanak sağlar.
    • Üçüncü taraflarla verilerin kimliğinizin belirlenmesini sağlayacak kısımlarını
    çıkarıp kişi adlarının gizli tutulduğu veriler paylaşırız. Ayrıca bilgilerinizi artık
    sizinle ilişkilendirilemeyecek şekilde başka bilgilerle birleştirip bu toplu bilgileri
    paylaşabiliriz.
    Kullanıcı İçeriklerinizi paylaşmayı seçebileceğiniz taraflar:
    • Denemapp Hizmetinde paylaştığınız bilgileri kaldırırsanız, bu bilgilerin kopyaları
    Denemapp Hizmetinin önbelleğe alınmış veya arşivlenmiş sayfalarında, üçüncü
    tarafların bu bilgileri kopyalamış veya kaydetmiş olması halinde görüntülenmeye
    devam edebilir.
    Yönetim değişimi durumunda ne olur:
    • Denemapp veya varlıklarımızın bir bölümünü veya tamamını başka bir kuruluşa
    satmamız veya başka bir şekilde devretmemiz halinde (örneğin birleşme, iktisap,
    iflas, fesih, tasfiye gibi bir işlem sırasında), adınız, cep telefonunuz ve e-posta
    adresiniz gibi bilgileriniz, Kullanıcı İçerikleri ve Denemapp Hizmeti aracılığıyla
    toplanan diğer tüm bilgiler, satılan veya devredilen ürünler arasında olabilir.
    Kullanıcı İçerikleriniz size ait olmaya devam eder. satın alan veya devralan tarafın
    bu Gizlilik İlkesinde verdiğimiz taahhütleri yerine getirmesi gerekecektir.
    Yasal talepleri yerine getirme ve kötülüğü engelleme:
    • Yasal bir talebi (arama izni, mahkeme emri veya mahkeme celbi gibi) yerine
    getirmek için bilgilerinize erişebilir, onları saklayabilir ve paylaşabiliriz. Ayrıca,
    dolandırıcılık ve yasa dışı başka bir faaliyeti saptamak, önlemek ve çözümlemek;
    soruşturmaların bir parçası olduğu durumlar da dahil kendimizi, sizi ve diğerlerini
    korumak için gerekli olduğunu iyi niyet çerçevesinde düşündüğümüz durumlarda
    da bilgiye erişebilir, saklayabilir ve paylaşabiliriz. Sizinle ilgili aldığımız bilgilere,
    Denemapp ile yapılan alışverişlerle ilgili mali işlem verileri de dahil, yasal bir
    talebin veya zorunluluğun, resmi bir soruşturmanın veya koşul ya da ilkelerimizin
    olası ihlalleriyle ilgili soruşturmaların konusu olduğunda ya da zararın önlenmesi
    amacıyla uzun bir süre boyunca erişilebilir ve bu bilgiler işlenip tutulabilir. Kötüye
    kullanımın veya koşullarımızın diğer ihlallerinin tekrarlanmasını önlemek için
    koşullarımızı ihlal ettiklerinden dolayı kapanan hesaplardaki bilgileri en az bir yıl
    boyunca tutabiliriz.
    <div class="title">4. BİLGİLERİNİZİ NASIL DEPOLUYORUZ</div>
    • Denemapp Hizmeti aracılığıyla toplanan bilgileriniz, Türkiye’de veya
    Denemapp’in İştiraklerinin veya Hizmet Sağlayıcılarının tesislerinin bulunduğu
    başka bir ülkede depolanıp işlenebilir.
    • Denemapp, İştirakleri veya Hizmet Sağlayıcıları kişisel bilgiler dahil sizin
    hakkınızda topladığımız bilgileri denizaşırı ve bulunduğunuz ülke veya yargı
    bölgesinden dünya çapında başka ülkelere veya yargı bölgelerine aktarabilir.
    Kişisel bilgiler dahil olmak üzere bilgilerinizi, bulunduğunuz yargı bölgesindeki
    veri koruma yasalarının aynısına sahip olmayan başka bir ülke ve yargı bölgesine
    aktarabileceğimizi lütfen unutmayın.
    • Denemapp Hizmetine kaydolarak ve Denemapp Hizmetini kullanarak,
    bilgilerinizin Türkiye’ye veya Denemapp’in İştiraklerinin veya Hizmet
    Sağlayıcılarının tesislerinin bulunduğu başka bir ülkeye aktarılmasına ve
    hakkınızdaki bilgilerin bu Gizlilik İlkesinde belirtilen şekilde kullanılmasına ve
    ifşasına izin verirsiniz.
    • Denemapp Hizmeti aracılığıyla toplanan bilgileri güvende tutmak için ticari açıdan
    makul güvenlik önlemlerini almaktayız ve hesabınıza erişmenize izin vermeden
    önce kimliğinizi doğrulamak için makul yöntemler (doğrulama kodu girilmesini
    istemek gibi) kullanmaktayız. Ancak, Denemapp olarak, Denemapp’e aktardığınız
    bilgilerin güvenliğini garanti edemeyiz veya Denemapp Hizmetinde bulunan
    bilgilerin erişilemez, ifşa edilemez, değiştirilemez veya yok edilemez olduğuna dair
    garanti veremeyiz.
    • Doğrulama kodunuzun ve hesap bilgilerinizin gizliliğini korumak ve sizinle
    Denemapp arasındaki e-postalara ve SMS’lere erişimi kontrol etmek her zaman
    sizin sorumluluğunuzdadır. Denemapp’e bağladığınız sosyal medya servislerinin
    hizmetlerinde yaptıkları değişiklikler de gizlilik ayarlarınızı etkileyebilir. Başka
    hiçbir kuruluşun işlevsellik, gizlilik veya güvenlik önlemlerinden sorumlu değiliz.
    <div class="title">5. BİLGİLERİNİZ HAKKINDAKİ SEÇİMLERİNİZ</div>
    Hesap bilgileriniz:
    • Uygulama üzerinden hesap bilgilerinizi istediğiniz zaman güncelleyebilirsiniz.
    • E-posta iletişimlerinde verilen “abonelikten çıkma bağlantısına” tıklayarak, bizden
    gelen e-posta iletişimlerinin aboneliğinden çıkabilirsiniz. Yukarıda da belirtildiği
    gibi, hesap doğrulama, alışveriş ve faturalandırma onayları ve Denemapp
    Hizmetinin özellikleri hakkındaki hatırlatmalar, değişiklikler/güncellemeler ve
    teknik ve güvenlik bildirimleri gibi Denemapp Hizmetine ilişkin iletişimleri
    almayı durduramazsınız.
    Kullanıcı Bilgilerinizi saklama süremiz:
    • Hesabınızın kapatılması veya dondurulmasından sonra, Denemapp, İştirakleri veya
    Hizmet Sağlayıcıları hesap bilgilerinizi de içeren bilgileri ve Kullanıcı İçeriklerini
    yedekleme, arşivleme ve/veya denetim amacıyla ticari açıdan makul bir süre
    boyunca saklamaya devam edebilir.
    <div class="title">6. DİĞER İNTERNET SİTELERİ VE HİZMETLER</div>
    Denemapp Hizmetimizden erişilen veya Denemapp Hizmetimizden bağlantı verilen
    hiçbir internet sitesinin veya hizmetin uygulamalarından, bulundurdukları içerikler dahil,
    sorumlu değiliz. Denemapp Hizmetimizden başka bir internet sitesine veya hizmete
    gitmek için bir bağlantı kullandığınızda, Gizlilik İlkemizin o üçüncü taraf internet
    sitelerinde veya hizmetlerinde geçerli olmadığını lütfen unutmayın. İnternet sitemizde
    bağlantısı olanlar dahil, üçüncü taraf internet siteleri veya hizmetlerinde gezinmeniz ve
    etkileşimde bulunmanız, üçüncü tarafın kendi kurallarına ve ilkelerine tabidir. Ayrıca,
    Kullanıcı İçeriklerinize erişmesine izni verdiğiniz üçüncü taraflardan sorumlu
    olmadığımızı ve üzerlerinde kontrolümüzün olmadığını kabul edersiniz. Üçüncü taraf bir
    internet sitesini veya hizmetini kullanıyorsanız ve Kullanıcı İçeriklerinize erişmesine izin
    veriyorsanız, bunun riski tamamen sizin sorumluluğunuzdadır.
    <div class="title">7. BİZİMLE İLETİŞİME GEÇME</div>
    Bu Gizlilik İlkesi veya Denemapp Hizmeti hakkında sorularınız varsa,
    lütfen <span class="title">support@denemapp.com</span> adresinden bizimle iletişime geçin.
    <div class="title">8. GİZLİLİK İLKEMİZDE DEĞİŞİKLİKLER</div>
    Denemapp bu Gizlilik İlkesini zaman zaman değiştirebilir veya güncelleyebilir, bu yüzden
    düzenli olarak gözden geçirmenizi tavsiye ederiz. Size değişiklikleri veya güncellemeleri
    mevcut koşullara uygun başka biçimlerde bildirebiliriz. Bu Gizlilik İlkesinde değişiklik
    yapıldıktan sonra Denemapp’ı veya Denemapp Hizmetini kullanmaya devam etmeniz,
    yapılan değişiklikleri kabul ettiğiniz anlamına gelir.
  </div>

  <div class="privacy-header"> KVKK Aydınlatma Metni</div>


  Denemapp. tarafından gizliliğinize saygı duyulmakta ve veri güvenliğinize
  önem verilmektedir. Bu kapsamda 6698 sayılı Kişisel Verilerin Korunması Kanunu
  (‘Kanun”) ve ilgili diğer mevzuatlar dahilinde sizleri bilgilendirmek ve aydınlatmak
  amacıyla işbu metin hazırlanmıştır.
  <div class="title">Tanımlar</div>
  İşbu aydınlatma metninde geçen;
  Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
  Kişisel Verilerin Korunması Kanunu (“KVKK”): 7 Nisan 2016 tarihinde
  Resmi Gazete’de yayınlanarak yürürlüğe giren 6698 sayılı Kişisel Verilerin
  Korunması Kanunu’nu.
  Veri işleyen: Veri sorumlusunun verdiği yetkiye dayanarak onun adına Kişisel
  Verileri işleyen gerçek veya tüzel kişiyi,
  Veri Sorumlusu: Kişisel Verilerin işleme amaçlarını ve vasıtalarını
  belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden
  sorumlu olan gerçek veya tüzel kişiyi,
  ifade eder.
  <div class="title">Veri Sorumlusu</div>
  <div class="title">Denemapp Şirketi</div>
  Biz, Denemapp olarak; müşterilerimiz, potansiyel müşterilerimiz, çalışan adaylarımız,
  çalışanlarımız, şirket hissedarlarımız, şirket yetkililerimiz, ziyaretçilerimiz, tedarikçiler, iş
  ortakları, işbirliği içinde olduğumuz kurumların çalışanları, hissedarları ve yetkilileri ve 3.
  kişiler ile ilgili uhdemizde bulunan kişisel verilerinin Türkiye Cumhuriyeti Anayasası,
  ülkemizin tarafı olduğu uluslararası sözleşmeler ile 6698 sayılı Kişisel
  Verilerin Korunması Kanunu (“KVKK”) başta olmak üzere ilgili mevzuat çerçevesinde;
  güvence altına alınması, işlenmesi ve imhası konusuna hassasiyetle yaklaşmaktayız.
  Bu çerçevede, KVKK kapsamında Veri Sorumlusu sıfatıyla sizleri aydınlatmak
  istiyoruz. Şirketimiz ile müşterileri, potansiyel müşterileri, çalışan adayı, çalışan,
  şirket hissedarı, şirket yetkilisi, ziyaretçi, tedarikçi, iş ortakları, işbirliği içindeki
  kurum çalışanı, hissedarı, yetkilisi sıfatı ile paylaştığınız kişisel verileriniz KVKK’
  ya uygun şekilde, faaliyet ve hizmet amaçlarımız ile bağlantılı ve ölçülü olarak
  işlenebilecek. yurt içi ve yurt dışındaki üçüncü kişilere aktarılabilecek, saklanacak,
  profilleme için kullanılabilecek ve sınıflandırılabilecektir.
  <div class="title">Kişisel Verilerinizin işlenme Amacı</div>
  KVKK’nın 4., 5. ve 6. maddeleri uyarınca kişisel verileriniz;
  1. Hukuka ve dürüstlük kuralının öngördüğü biçimde,
  2. İşlenme amaçları ile bağlantılı, sınırlı ve ölçülü olarak,
  3. Doğru ve güncel olarak,
  4. Belirli açık ve meşru amaçlar ile
  5. ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar
  işlenecektir.
  Veri sorumlusu olarak Şirketimiz tarafından kişisel verileriniz, kalitemizden ödün
  vermeden, sizleri daha iyi tanıyarak ihtiyaçlarınızı anlamak, isteklerinize daha hızlı
  cevap verebilmek ve sizlerle olan iletişimimizi geliştirerek sizlere daha iyi hizmet
  vermek, bunları yapabilmek için analiz yapmak, sizleri hizmetlerimiz hakkında
  bilgilendirebilmek, hizmetlerimiz hakkında memnuniyetinizi ölçmek, istekleriniz ve
  ihtiyaçlarınız doğrultusunda ve hizmetlerimizi geliştirmek ve çeşitlendirmek için ve
  bunlarla birlikte gerekli kalite ve standart denetimlerimizi yapabilmek ya da mevzuat
  tarafından öngörülen raporlama ile şirketimizle iş ilişkisi içerisinde olan kişiler ile hukuki
  ve ticari yükümlülüklerin gerçekleştirilmesinin temini amacıyla hukuki, ticari, mali, idari
  operasyonlarla şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanmasına dair
  sair yükümlülüklerimizin yerine getirilmesi gibi amaçlar için işlenebilecektir.
  Çalışanlarımızın verileri, iş Kanunu ve çalışma ve sosyal güvenlik mevzuatı ile yürürlükte
  olan diğer mevzuatın öngördüğü zorunlulukların dahilinde veya performans düzeyini ve
  çalışan memnuniyetini arttırmak ve iş güvenliği ve iş barışının sağlanması gibi işletmesel
  nedenler ile Şirketimiz veya Şirketimizin işbirliği yaptığı veya yetkilendirdiği gerçek
  ve/veya tüzel kişiler tarafından işlenebilecektir.
  <div class="title">Kisisel Verilerinizin Aktarılması</div>

  Kişisel verileriniz yukarıda sayılan amaçlar dahilinde, KVKK ve yürürlükte olan mevzuata
  uygun olarak, Grup şirketleri ile paylaşılabileceği gibi, sayılanlar ile sınırlı olmamak üzere;
  hissedarlarımız, iş ortaklarımız, iştiraklerimiz, tedarikçilerimiz, iş bağlantılarımız, ifa
  yardımcılarımız ve alt yüklenicilerimiz ile sunduğumuz hizmetin amacı doğrultusunda ya da
  düzenleyici denetleyici kurumlar ve resmi mercilerle ilgili mevzuatın öngördüğü durumlarda
  yurt içine veya yurt dışına aktarılabilecektir.
  <div class="title"> Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi</div>
  Kişisel verileriniz, yukarıda belirtilen amaç ve kapsamda sözlü, yazılı veya elektronik
  yollarla toplanabilir.Kişisel verileriniz, Şirketimiz veya Şirketimiz adına veri işleyen
  gerçek ya da tüzel kişiler tarafından sayılanlarla sınırlı olmamak üzere, iletişim formları,
  tüketici şikayet formları, sosyal ağlar, internet sitesi, muhtelif sözleşmeler, mobil
  uygulamalar, elektronik posta, başvuru formları gibi araçlar üzerinden, Şirketimiz ile
  yapılan yazılı veya sözlü iletişimler vb. kanallar aracılığıyla sözlü, yazılı veya elektronik
  ortamda toplanmaktadır.
  <div class="title">Verilerin Saklanması ve Silinmesi</div>
  Şirketimiz, işlediği kişisel verileri mevzuatla belirlenen sürelerde saklamakta olup,
  mevzuatta ayrıca süre belirlenmemiş olması halinde; kişisel veriler şirketimizin o veriyi
  işlerken sunduğu hizmetlerle bağlı olarak Şirketimizin uygulamaları ve ticari
  yaşamın teamülleri uyarınca işlenmesini gerektiren süre kadar ve bu süreden sonra
  sadece olası hukuki uyuşmazlıklarda delil teşkil etmesi amacıyla uygulamada gerekliliği
  ortaya konulan süreler boyunca saklanmaktadır. Belirtilen sürelerin sona ermesinden sonra
  söz konusu kişisel veriler silinmekte, yok edilmekte veya anonim hale getirilmektedir.
  <div class="title">Kisisel Veri Sahibi Olarak Haklarınız</div>

  KVKK’nın 11. maddesi çerçevesinde,
  <div>1. Kişisel verilerinizin işlenip işlenmediğini öğrenme,</div>
  <div>2. Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</div>
  <div>3. Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
    kullanılmadığını öğrenme,
  </div>
  <div>4. Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</div>
  <div>5. Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini
    isteme,
  </div>
  <div>6. KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini
    veya yok edilmesini
    isteme,
  </div>
  <div>7. Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok
    edilmesini talep
    ettiğinizde, bu durumun kişisel verilerinizin aktarıldığı üçüncü kişilere
    bildirilmesini isteme,
  </div>
  <div>8. İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
    suretiyle aleyhinize bir
    sonucun ortaya çıkmasına itiraz etme,
  </div>
  <div>9. Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız
    halinde bu zararın
    giderilmesini talep etme,
  </div>
  haklarına sahipsiniz. Bu haklarınızı kullanmak ile ilgili talebinizi support@denemapp.com
  adresine e-mail olarak iletebilirsiniz.Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda
  belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı talep ettiğiniz haklara ilişkin
  açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması, talep
  ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor iseniz bu
  konuda özel olarak yetkili olmanız ve yetkinizin belgelendirilmesi, başvurunun kimlik ve
  adres bilgilerini içermesi ve başvuruya kimliğinizi tevsik edici belgelerin eklenmesi
  gerekmektedir.
  KVKK uyarınca Kişisel Verilerinizi Açık Rızanız Olmaksızın işleyebileceği Haller:
  KVKK’nın 5. maddesi uyarınca, aşağıdaki hallerde, açık rızanız aranmaksızın yukarıda
  belirtilen
  ve kanuna uygun olarak kişisel verileriniz işlenebilmektedir;
  <div>* Kanunlarda açıkça öngörüldüğü hallerde,</div>
  <div>* Fiili imkansızlık nedeniyle veri sahibi olarak rızanızı açıklayamayacak durumda
    olmanız veya rızanıza
    hukuki geçerlilik tanınmayan hallerde kendinizin ya da bir başkasının hayatı veya
    beden bütünlüğünün
    korunması için kişisel verinizin işlenmesinin zorunlu olması,
  </div>
  <div>* Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
    sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,
  </div>
  <div>* Şirketimizin bir hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</div>
  <div>* Kişisel verinizin tarafınızca alenileştirilmiş olması,</div>
  <div>* Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</div>
  <div> * Sahip olduğunuz temel hak ve özgürlüklerinize zarar vermemek
    kaydıyla, şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu
    olması.
  </div>
</div>
